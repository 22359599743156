h1,
h2,
h3,
h4,
h5 {
    text-transform: uppercase;
}

.quote {
    font-weight: 100;
    font-style: italic;

    .author{
        font-weight: 900;
        font-style:normal;
    }
}


h1{
    font-size: 3rem;
}


//Размеры
.tp-xxl{
    font-size: 2.5rem;
}

.tp-xl{
    font-size: 2rem;
}

.tp-l{
    font-size: 1.5rem;
}

.tp-m{
    font-size: 1rem;
}

.tp-s{
    font-size: .8rem;
}

.tp-xs{
    font-size: .7rem;
}

.tp-xxs{
    font-size: .6rem;
}



//Жирность
.tp-thin {
    font-weight: 100;
}

.tp-light {
    font-weight: 300;
}

.tp-normal {
    font-weight: 500;
}

.tp-bold {
    font-weight: 700; /* Жирный */
}

.tp-black {
    font-weight: 900; /* Самый жирный */
}



//Особенности
.tp-upper{
    text-transform: uppercase;
}

.tp-ls{
    letter-spacing: .2rem;
}

