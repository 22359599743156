@import './imports.scss';

.is-developing-container{
    position: fixed;

    bottom: 2rem;
    right: 2rem;

    background-color: #ffffff;

    padding: 2rem;

    z-index: 9999;

    display: flex;
    align-items: center;

    align-items: flex-start;

    gap: 2rem;

    transition: all .7s ease-in-out;

    h5{
        font-size: 1.3rem;
    }
    p{
        font-size: 1rem;
    }

    box-shadow: 0 0 30px rgba($color: #000000, $alpha: .1);

    .btn-is-developing{
        padding: .5rem;
        background-color: rgba($color: #000000, $alpha: .1);
        text-transform: uppercase;
        display: inline-block;

        margin-top: 1rem;
        transition: all .3s ease-in-out;

        &:hover{
            cursor: pointer;
            background-color: rgba($color: #000000, $alpha: .3);
        }
    }

    transform: translateY(0);

    &.activeInfo{
        // transform: translateY(-10rem);
    }
}


.is-developing-info{
    opacity: 0;

    position: fixed;

    bottom: 2rem;
    right: 2rem;

    background-color: #ffffff;

    padding: 2rem;

    z-index: 9999;

    transition: all 1s ease-in-out;
    pointer-events: none;

    h5{
        font-size: 1.3rem;
    }
    p{
        font-size: 1rem;
    }

    &.activeInfo{
        opacity: 1;
        pointer-events: all;

    }
}

// Спиннер
.spinner {
    width: 1.6rem;
    height: 1.6rem;
    border: 5px solid #dfdfdf; /* Светлая граница */
    border-top: 5px solid #3498db; /* Синяя верхняя граница */
    border-radius: 50%;
    animation: spin 1.2s linear infinite; /* Анимация вращения */
    // margin-bottom: 20px; /* Отступ от текста */
}

// Анимация вращения
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.is-developing-info {
    opacity: 0;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: #ffffff;
    padding: 2rem;
    z-index: 9999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: all 0.5s ease;

    &.activeInfo {
        opacity: 1;
    }

    h5 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .ready-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .name {
            font-size: 1rem;
            color: #555;
        }

        .progress-bar {
            background-color: #eee;
            height: 10px;
            margin: 0.5rem 0;
            overflow: hidden;

            .progress {
                height: 100%;
                background-color: #3498db;
                transition: width 0.5s ease;
            }
        }

        .value {
            font-size: 0.9rem;
            color: #333;
        }

        .status {
            margin-top: 0.5rem;

            .true {
                color: #27ae60;
                font-weight: bold;
            }

            .false {
                color: #e74c3c;
                font-weight: bold;
            }
        }
    }
}