@import './imports.scss';

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #333;
  }
  
  .footer__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4rem;
  }
  
  .footer__links div {
    display: flex;
    flex-direction: column;
  }
  
  .footer__links a, .footer__links Link {
    text-decoration: none;
    color: #333;
    margin-bottom: 5px;
  }
  
  .footer__info {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .footer__social {
    display: flex;
    align-items: center;

    align-self: flex-end;
    justify-self: flex-end;

    flex-direction: column;

    gap: 1rem;

    border: solid rgba($color: $dark-grey, $alpha: .4) 1px;
    padding: 1rem;

    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .footer__social svg {
    width: 3rem;
    height: 3rem;
  }
  
  .footer__social div {
    display: flex;
    flex-direction: column;
  }
  

  .footer-second{
    display: grid;
    grid-template-columns: 1fr 1fr; 
    width: 100%;

    justify-content: start;
    align-items: start;
  }