@import 'imports.scss';

button{
    cursor: pointer;
    padding: 1rem 2rem;

    border: none;
    justify-self: flex-end;

    transition: all .3s ease-in-out;
    
    &:hover{
        background-color: #69727d;
        color: white;
    }
}