h1 {
    font-weight: 700;
    font-size: 5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

h2 {
    font-size: 2rem;
}

.header-style {
}

p {
    font-size: 1.2rem;
    font-weight: 200;
}

* {
    margin: unset;
    color: #3D3D3D;
}

.block-wrapper {
    // width: 90%;
    max-width: 1400px;
    margin: 0 auto;

    padding: 2rem 3rem;
}

.block-background {
    &.full-screen {
        min-height: 100vh;
        padding: 3rem 0;
    }

    min-height: 80vh;
    padding: 3rem 0;
    box-sizing: border-box;

    background-size: cover !important;
    background-repeat: no-repeat !important;

    display: flex;
    align-items: center;
    // justify-content: flex-start;

    &:first-child{
        
        padding-top: 0rem;
    }
}

a {
    position: relative;
    &:hover {
        // color: red !important;
        &::before {
            opacity: 1 !important;
        }
    }
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';

        width: 100%;
        height: 1px;

        background-color: black;
        opacity: 0;

        transition: all 0.2s ease-in-out;
    }

    &.text {
        &::before {
            opacity: 1;
            background-color: inherit;
        }

        &:hover{
            
        }
    }
}

// .block{
//     display: flex;
//     flex-direction: column;
//     gap: 3rem;
// }
