@import './imports.scss';

.form-main {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    justify-content: space-between;



    background-color: white;
    padding: 1rem;
    // height: auto;
    width:25rem;

    box-sizing: border-box;

    h3{
        color: $dark-grey;
        font-size: 1.5rem;
    }
}

.form-main__info{
    color: $dark-grey;
    font-size: .8rem;
    // text-align: center;
}

.form-main__input-list{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.form-main input {
    padding: 1rem 1rem;
    border: 1px solid rgba($color: #69727d, $alpha: .3);
    outline: none;


    &:focus {
    }
}

.form-main button {
    cursor: pointer;
    padding: 1rem 1rem;

    border: none;
    justify-self: flex-end;

    transition: all .3s ease-in-out;
    
    &:hover{
        background-color: #69727d;
        color: white;
    }
}
