@import './imports.scss';

nav {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    // align-content: center;
    // flex-wrap: wrap;

    img {
        height: 0.7rem;
        object-fit: contain;
    }

    * {
        height: 2rem;
        font-weight: 100;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: all 0.3s ease-in-out;

        cursor: pointer;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';

            width: 100%;
            height: 1px;

            background-color: black;
            opacity: 0;

            transition: all 0.2s ease-in-out;
        }
    }

    a {

        
        &:hover {

            
            &::before {
                opacity: 1;
            }

            
        }
    }
}

.activeLink:not(.nav-image) {
    &::before {
        opacity: 1;
    }
}

.nav-image{
    &::before{
        display: none;
    }
}

.block-nav {
    background-color: white;

    position: fixed;
    top: 0;
    width: 100%;

    z-index: 4;

    .block-wrapper {
        padding: 1.5rem 3rem;
    }
}
