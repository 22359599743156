@import './imports.scss';

.faq-container {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 2rem;

    width: 100%;
    // max-width: 100%;

    align-items: center;

    * {
        min-height: 0;
        min-width: 0;
    }
}

.faq-container__left {
    max-width: 100%;
}

.faq-container__left .swiper {
    max-width: 100%;
    height: 20rem;
}

.swiper-FAQ {
    width: 100%;
    height: 100%;

    min-height: 50rem;
}
.swiper-slide-FAQ {
    width: 100%;
    height: 100%;
    content: '';

    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.faq-container__right {
    display: flex;
    flex-direction: column;

    gap: 3rem;
    align-items: flex-start;

    height: auto;
}
