@import './imports.scss';

.home-background {
    // background: url('../images/home-background.webp'), linear-gradient(#222222, #3a3a3a);

    // background-blend-mode: overlay;

    // background-image: image-set(url('../images/home-bg.webp') 1x, url('../images/home-bg.webp') 2x);
    // background-position: center center;
    // background-attachment: fixed;

    // display: flex;
    // align-items: center;

    // color: $light-grey;
    // position: relative;

    align-items: center;
    background-attachment: fixed;
    background-position: 50%;
    color: #eee;
    display: flex;
    position: relative;

    // &::before {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     content: '';
    //     pointer-events: none;

    //     background: linear-gradient(#222222, #3a3a3a);
    //     opacity: 0.3;

    //     height: 100%;
    //     width: 100%;

    //     z-index: 1;
    // }

    h1 {
        line-height: 107%;
    }
}

// .home-background > * {
//     z-index: 2;
// }

.block-wrapper--home {
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    justify-items: end;

    position: relative;

    z-index: 3;

    // padding-top: 4rem;

    gap: 2rem;
    
     h1, p:not(.form-main__info){
        color: $light-grey;
    }
    

    // min-height: 90vh;
}

.block-whatwedo-background {
    background: $white-grey;
}

.home-background__logo-usm {
    height: 8rem;
}

.home-header {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;

    font-size: 3rem;
}

.swiper.swiper-bg {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    pointer-events: none;

    z-index: 1 !important;

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        // background-attachment: fixed;
    }

    .slide-image {
        width: 100%;
        height: 100%;
        content: '';

        background-position: center center;
        background-size: cover;
        background-attachment: fixed;

    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;

        position: absolute;

        background-color: rgba($color: #000000, $alpha: 0.5);
        z-index: 2;
    }
}
