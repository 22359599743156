@import './imports.scss';

.contacts-container {
    padding: 20px;
    text-align: center;
}

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.contact-item {
    background-color: $light-grey; /* Светлый фон */
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: .5rem;
}




