@import './imports.scss';

.accordion {
    list-style-type: none;
// padding: 1rem;
    // background-color: #dcdcdc;
    border-radius: 7px;

    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    &--imgs{
        gap: 0;
    }
}

.accordion-item {
    // margin-bottom: 16px;
    
    border-radius: 7px;
}

.accordion-header {
    position: relative;
    width: 100%;
    // padding: 20px 60px 20px 30px;
    background-color: $light-grey;
    border: 0;
    font-size: 1rem;
    text-align: left;
    cursor: pointer;

    padding: 1rem 1rem;

    transition: all .3s ease-in-out;

    font-weight: 700;

    &:hover{
        filter: brightness(.9);
    
    }

    &.active{
        filter: brightness(.9);
    }
}

.accordion-collapse {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
}

.accordion-collapse.open {
    height: auto;
}

.accordion-body {
    padding: 1rem;
    background-color: #f1f1f1;

    font-size: 1rem;
    color: #393939;
}
