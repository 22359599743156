body {
    margin: 0;
    font-family: 'Circe2', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 16px; /* Размер шрифта корневого элемента */
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: unset;
}

@font-face {
    font-family: 'Circe2';
    src: local('Circe2'), url('/fonts/Circe-ExtraBold.woff') format('woff');
    font-weight: 900;

    font-display: swap;
}

@font-face {
    font-family: 'Circe2';
    src: local('Circe2'), url('/fonts/Circe-Bold.woff') format('woff');
    font-weight: 700;

    font-display: swap;
}

@font-face {
    font-family: 'Circe2';
    src: local('Circe2'), url('/fonts/Circe-Regular.woff') format('woff');
    font-weight: 500;

    font-display: swap;
}

@font-face {
    font-family: 'Circe2';
    src: local('Circe2'), url('/fonts/Circe-Light.woff') format('woff');
    font-weight: 300;

    font-display: swap;
}

@font-face {
    font-family: 'Circe2';
    src: local('Circe2'), url('./fonts/Circe-ExtraLight.woff') format('woff');
    font-weight: 100;

    font-display: swap;
}
