@import './imports';

.form-background{

    background-image: url('../images/bg-images/home-bg.webp');
    position: relative;
    color: $light-grey;

    background-position: center center;

    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: .3);
        pointer-events: none;
    }
}

.block-wrapper--form{
    z-index: 3;
}