@import './imports.scss';

.cases-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.case-list__item {
    background-color: $light-grey;
    padding: 1rem;

    transition: all 0.3s ease-in-out;

    &.onHover {
        * {
            color: inherit;
        }
    }

    --before-color: transparent;
    a::before {
        background-color: var(--before-color);
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    align-items: flex-start;
}

.block--cases-list {
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    gap: 3rem;

    padding-top: 5rem;
}

.cases-list-header {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 2rem;
}

.case-list-header {
    // max-width: 60rem;

    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 2rem;

    * {
        min-height: 0;
        min-width: 0;
    }

    .swiper-wrapper {
        height: 40vh;
    }

    .swiper-slide-case-header{
        width: 100%;
        height: 100%;
        content: '';


        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
    }
}

.case-list-header__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}


.cases-list-container{

    h2{
        margin-bottom: 1rem;
    }
}