@import './imports.scss';

.main-wishes-container {
    display: grid;

    grid-template-columns: 2fr 1.5fr;

    gap: 2rem;
    align-items: center;

    * {
        min-width: 0;
    }
}

.video-block {
    width: 100%;
    height: 20rem;

    // overflow: hidden;
}

.swiper-text {
    max-width: 100%;

    // .swiper-wrapper{
    //     width: 100%;
    // }


    .swiper-slide-active {
        opacity: 1;
    }

    .swiper-slide-prev{
        opacity: 0 !important;
    }
    .swiper-slide-next{
        opacity: 0 !important;
    }
}

.swiper-slide-text {
    background-color: white;

    font-size: 1.2rem;
}

.main-wishes-container__text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.swiper-slide-text {
    // width: 100%;
    // // padding: 10px;
    // box-sizing: border-box; /* Чтобы паддинги не влияли на ширину */
    // // overflow: hidden; /* Скрыть переполнение */
    // // text-align: center; /* Центрирование текста */
    // white-space: normal; /* Для переноса длинных строк */
    // word-wrap: break-word; /* Перенос длинных слов */
}
