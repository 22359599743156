@import '../imports.scss';

.case-quote-block {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    gap: 2rem;
}

.case-quote-block__left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.case-quote {
    font-style: italic;
}

.block-background--case-color {
    min-height: unset;

    .block-wrapper {
        width: 100%;
    }
}

.case-colors__color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
        color: $light-grey;
        margin-bottom: 0.8rem;
    }
}

.case-colors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    justify-content: space-between;

    .color-block {
        width: 15rem;
        height: 15rem;

        box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: 0.3);

        &--badge {
            background-size: cover;
        }
    }
}

.case-quote-block__right {
    display: grid;

    * {
        min-height: 0;
        min-width: 0;
    }
}

.swiper-case .swiper-pagination-bullet {
    background-color: #004f32;
    opacity: 0.5;
}

.swiper-case .swiper-pagination-bullet-active {
    background-color: #004f32;
    opacity: 1;
}

.swiper-case.swiper-case--hr-capital .swiper-pagination-bullet {
    background-color: #E49E48;
    opacity: 0.5;
}

.swiper-case.swiper-case--hr-capital .swiper-pagination-bullet-active {
    background-color: #ff9e27;
    opacity: 1;
}

.swiper-case.swiper-case--mfitness .swiper-pagination-bullet {
    background-color: #1D2645;
    opacity: 0.5;
}

.swiper-case.swiper-case--mfitness .swiper-pagination-bullet-active {
    background-color: #1D2645;
    opacity: 1;
}

.slide-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    // border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.block-wrapper--quote {
    width: 100%;
}

.swiper-case {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .swiper-pagination{
        position: relative;
    }
}

.style-guide-item__photos-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    .style-photo-illustration {
        width: 20rem;
        height: 20rem;
        background-size: cover;
    }
}

.style-guide-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@keyframes float-rotate {
    0% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(25deg);
    }
    100% {
        transform: rotate(15deg);
    }
}

.block-background--style-guide {
    background: linear-gradient(45deg, #004f32, #013321);
    color: $light-grey;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 40rem;
        height: 40rem;
        background-repeat: no-repeat;
        background-size: contain;

        top: 35%;
        left: 50%;

        opacity: 0.05;
        transform: rotate(15deg);

        animation: float-rotate 12s ease-in-out infinite;

        background-image: url('../../images/cases/mamma-mia/mamma-mia-logo.svg');
    }

    .swiper {
        max-width: 100%;
    }

    h2 {
        margin-bottom: 2rem;
    }

    * {
        color: inherit;
    }

    .swiper {
        position: relative;

        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .swiper-pagination-progressbar {
        position: relative;
        // top: -2rem;

        .swiper-pagination-progressbar-fill {
            background-color: $light-grey;
        }
        // background-color: red;
    }
}


.block-background--case-color{
    padding: 4rem 0rem;

    p{
        margin-bottom: 1rem;
    }

    *{
        color: $light-grey;
    }

    .block-wrapper{
        // box-sizing: border-box;
    }
}


.block-background--style-guide.hr-capital{

}