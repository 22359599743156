@import './imports.scss';

.HIW-container {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    gap: 2rem;

    * {
        min-width: 0;
        min-height: 0;
    }

    align-items: center;
}

.swiper-HIW {

    width: 100%;
    height: 100%;

    min-height: 50rem;
    
    

    .swiper-slide-HIW{
        width: 100%;
        height: 100%;
        content: '';


        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
    }


    .swiper-wrapper{
        height: 50rem;
    }
}
