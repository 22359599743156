@import './imports.scss';

.block-about {
    // background-image: url('../images/bg-images/home-bg.webp');

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;

    color: $light-grey;

    > * {
        z-index: 3;
    }

    &::before {
        width: 100%;
        height: 100%;
        z-index: 2;
        position: absolute;

        background-color: rgba($color: #000000, $alpha: 0.3);
        content: '';
    }

    * {
        color: inherit;
    }
}
.about-container {
    display: grid;
    grid-template-columns: 2.5fr 2fr;
    gap: 2rem;
    width: 100%;
    height: 100%;
    grid-auto-rows: minmax(100px, auto); // Регулируйте минимальный и максимальный размер строки по необходимости.
}

.logo-company{
    width: 16rem;
    object-fit: contain;
}

.aigul-photo {
    object-fit: cover;
    // width: 200px;
    // max-width: 100%;
    height: 0;
    width: 100%;
    // height: auto;
    // max-height: 100%;
    // align-self: start; // Выравнивание изображения сверху, чтобы оно не растягивалось слишком сильно.
}

.about-container__left{
    display: flex;
    flex-direction: column;

    gap: 3rem;

    p{
        width: 70%;
    }
}

.about-container__numbers{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    gap: 1rem;

    h3{
        font-size: 3rem;
    }
}