@import './imports.scss';

.cheap-uniform-container {
    width: 100%;

    display: grid;
    grid-template-columns: 1.5fr 3fr;
    gap: 1rem;

    min-height: 60vh;

    align-items: center;
}

.uniform-comparison-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;

    & > * {
        padding: 1rem;
    }

    & > :nth-child(even){
        opacity: .5;
    }
}

.header {
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0; /* Фон для заголовков */
    padding: 0.6rem;
    border-bottom: 2px solid #ddd; /* Разделительная линия */

    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    border: 1px solid #ddd; /* Границы ячеек */

    display: flex;
    align-items: center;
}
